import utils from '@/utils'
import store from '@/store'

const AndroidApp = window.AndroidApp
const resolves = {}

window.onAppCallback = (cbName, value) => {
  store.commit('log', `onAppCallback(cbName = ${cbName}, value = ${value})`)
  if (resolves[cbName]) {
    resolves[cbName](value)
    delete resolves[cbName]
  }
}
window.onAppKeyEvent = (keyCode, scanCode) => {
  store.commit('log', `onAppKeyEvent(keyCode = ${keyCode}, scanCode = ${scanCode})`)
  utils.dispatchEvent('keydown', {keyCode})
}
window.onVideoError = () => {
  store.commit('log', 'onVideoError')
  utils.dispatchEvent('videoerror')
}
window.onVideoStart = (d) => {
  store.commit('log', `onVideoStart(${d})`)
  utils.dispatchEvent('videostart')
}
window.onAudioTrackList = (trackList) => store.commit('log', `onAudioTrackList(${trackList})`)
window.onVideoBufferingBegin = () => {
  store.commit('log', 'onVideoBufferingBegin')
  utils.dispatchEvent('videoBufferingBegin')
}
window.onVideoBufferingEnd = () => {
  store.commit('log', 'onVideoBufferingEnd')
  utils.dispatchEvent('videoBufferingEnd')
}
window.onSubtitlesTrackList = () => store.commit('log', 'onSubtitlesTrackList')
window.onHdmiConnectEvent = (connect) => store.commit('log', `onHdmiConnectEvent(${connect})`)
window.App = {
  popupMessageScreen: {setMessageText() {}},
  display: {showPopupScreen() {}},
}

const funcs = {
  runYouTubeApp(model) {
    const appIdMap = {
      'NV501WAC': 'com.teamsmart.videomanager.tv',
      'NV711WAC': 'com.teamsmart.videomanager.tv',
    }
    const appId = appIdMap[model] ? appIdMap[model] : 'com.google.android.youtube.tv'
    return AndroidApp.CallSub('openAppById', false, appId)
  },
  runWinkApp() {
    return AndroidApp.CallSub('openAppById', false, 'ru.rt.video.app.tv')
  },
}

export default (args) => {
  args = args || {}
  args.params = args.params || []
  if (funcs[args.fName]) {
    return funcs[args.fName](...args.params)
  }
  if (!args.needCb) {
    return AndroidApp.CallSub(args.fName, args.async || false, ...args.params)
  }
  return new Promise((resolve, reject) => {
    const cbName = args.fName + '-' + Math.random()
    if (resolves[cbName]) {
      return reject(new Error(cbName + ' device call already running'))
    }
    resolves[cbName] = resolve
    AndroidApp.CallSub(args.fName, args.async || false, "'"+cbName+"'", ...args.params)
  }).then(res => {
    if (args.fName == 'deviceInfo') {
      const info = JSON.parse(res)
      return {
        mac: info.DevInfo.MacAddress,
        type: info.Common.Product + ' ' + info.Common.Release, //maybe info.Common.brand
        model: info.DevInfo.Model,
        serial: info.Device.Serial,
        firmware: info.Device.Display,
        width: info.Screen.Width,
        height: info.Screen.Height,
        apiVersion: info.Common.AppVersionCode,
      }
    }
    return res
  })
}
